// import { useNavigate } from "react-router-dom";
import betaTag from "../../../assets/tai/betaTag.svg";
import exitIcon from "../../../assets/tai/exitIcon.svg";
import StayHereModal from "./StayHereModal";
import SettingIcon from "assets/svg/Setting.svg";
import TAIDropdown from "./TAIDropdown";
import React, { useEffect, useState, useContext } from "react";
import { useDispatch, useSelector } from "react-redux";
import { ReactComponent as CaretDown } from "assets/tai/caretDown.svg";
import { ReactComponent as AssignKeywordsIcon } from "assets/tai/AssignKeywordsIcon.svg";
import cross from "../assets/svgs/cross.svg";
import CommonLoader from "../assets/Gif/CommonLoader.gif";
import { SAVE_CAMPAIGN_AUDIENCE_DETAILS } from "store/types/targetingAI";
import {
  getTargetingAIAudienes,
  saveCampaignAudience,
  setCampaignAudience,
  updateTAIRecommendedExclusions,
  setTAIUpdatedRecommendedExclusions,
  resetNodesLoaderState,
} from "../../../store/actions/targetingAI";
import { ReactComponent as GoogleLogo } from "../ReactFlowComponents/Assets/svgs/googleLogo.svg";
import { ReactComponent as FBLogo } from "../../../assets/svg/platforms/icons8-facebook.svg";
import { TaiContext } from "../TaiContextProvider";
import { useAuth0 } from "@auth0/auth0-react";
import {
  resetAdditionalInputData,
  resetGSCalibrationData,
  resetGSKeywordTuningData,
} from "store/actions/gsTargetingAI";
import {
  Box,
  Modal,
  Typography,
  Tab,
  Tabs,
  Select,
  InputLabel,
  MenuItem,
  Grid,
  IconButton,
  Stack,
  Checkbox,
  TextField,
  Divider,
  Tooltip,
} from "@mui/material";
import { setSelectedAiGroup } from "store/actions/BudgetPlannerAiGroup";
import SelectText from "Pages/SmbDashboard/common/SelectField";
import { Button, TextInput } from "components/shared";
import { getAdsetDetails, getGroupAdAccounts } from "store/actions/adaccount";
import { ReactComponent as GoogleAdsLogo } from "../../../assets/svg/google-ads-logo.svg";
import "./Header.scss";
import { getRolesHierarchy, isAdminRole } from "utils/commonFunctions";
import { useSearchParams } from "react-router-dom-v5-compat";

const ITEM_HEIGHT = 64;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      width: 250,
    },
  },
};
function TabPanel(props) {
  const { children, value, selectedTab } = props;
  return (
    <div
      role="tabpanel"
      hidden={selectedTab !== value}
      id={value}
      aria-labelledby={value}
      style={{
        height: "80%",
        width: "100%",
        padding: "24px",
        overflowY: "scroll",
      }}
    >
      {value === selectedTab && (
        <Box sx={{ overflow: "none", height: "80%" }}>{children}</Box>
      )}
    </div>
  );
}
const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: "36rem",
  bgcolor: "white",
  boxShadow: 24,
  p: 2,
  height: "38rem",
  maxHeight: "38rem",
  borderRadius: "0.5rem",
};
const Component = ({ setOpenModal }) => {
  const authState = useSelector((state) => state.auth);
  const { user } = authState;
  const {
    selectedAudience,
    selectedGroup,
    selectedControlGroup,
    selectedAdAccount = "",
  } = useSelector((state) => state.taiCommon?.campaignAudienceDetail);
  let campaignAudience = useSelector(
    (state) => state?.taiCommon?.campaignAudience?.value || null
  );
  // const storedData = localStorage.getItem("taiExclusions")
  //   ? JSON.parse(localStorage.getItem("taiExclusions"))
  //   : {};

  const [isLoading, setIsLoading] = useState(false);
  const [loadingIcon, setloadingIcon] = useState(false);
  const [selectedAiGroup, updateSelectedGroup] = useState(selectedGroup);
  const [selectedControlAiGroup, updateSelectedControlGroup] =
    useState(selectedControlGroup);
  const [selectedAdAcount, updateSelectedAdAcount] =
    useState(selectedAdAccount);
  const [adSetsOption, updateSelectedAdSetsOption] = useState([]);
  const [adSetsOptionGroup, updateSelectedAdSetsGroup] = useState([]);

  const [adAccountOptions, updateAdAccountOptions] = useState([]);

  const [selectedCustomAudience, updateSelectedCustomAudience] =
    useState(selectedAudience);

  const dispatch = useDispatch();
  const { formData } = useSelector((state) => state.aiInitiation);
  const { aiGroupOptions } = formData;
  const [fbAIGroupOptions, updateFBAIGroupOptions] = useState([]);
  const fbplatformId = "1c59a6e2-843b-4634-92a0-6fa93ce6c761";
  const googlePlatformId = "1c59a6e2-843b-4632-92a0-6fa93ce6c761";
  const handleAiGroupDropdown = (option) => {
    setloadingIcon(true);
    updateSelectedGroup(option);
    updateSelectedCustomAudience(null);
    updateSelectedAdAcount(null);
    campaignAudience = null;
  };

  const handleControlAiGroupDropdown = (option) => {
    updateSelectedControlGroup(option);
  };

  useEffect(() => {
    if (campaignAudience) setloadingIcon(false);
  }, [campaignAudience]);

  const handleAdAccountDropdown = (option) => {
    updateSelectedAdAcount(option);
    campaignAudience = null;
  };


  useEffect(() => {
    updateFBAIGroupOptions(
      JSON.parse(
        JSON.stringify(
          Object.values(aiGroupOptions).filter(
            (aigroup) =>
              aigroup.adAccounts.filter(
                (adaccount) =>
                  adaccount.platformId === fbplatformId ||
                  adaccount.platformId === googlePlatformId
              ).length > 0
          )
        )
      )
    );
  }, [aiGroupOptions]);

  useEffect(() => {
    const response = adSetsOptionGroup.filter((adset) => {
      const result = selectedAdAcount?.id === adset.adAccountId;
      return result;
    });

    updateSelectedAdSetsOption(
      response.map((item) => ({ ...item, selected: false }))
    );
  }, [selectedAdAcount]);

  useEffect(() => {
    if (selectedAiGroup && selectedAiGroup.id) {
      setIsLoading(true);
      updateSelectedControlGroup(null);
      dispatch(
        getTargetingAIAudienes(
          { aiGroupId: selectedAiGroup.id },
          (response, error) => {
            if (!error && response.data) {
              if (response.data.selectedAudience) {
                const selectedcontrolAiGroup = fbAIGroupOptions.find(
                  (item) =>
                    response.data.selectedAudience.controlAiGroup === item.id
                );
                updateSelectedControlGroup(selectedcontrolAiGroup);
                const selectedAudience = response.data.audiences.find(
                  (item) =>
                    response.data.selectedAudience.customAudienceId === item.id
                );
                dispatch({
                  type: SAVE_CAMPAIGN_AUDIENCE_DETAILS,
                  payload: {
                    selectedAudience: selectedAudience,
                    selectedGroup: selectedAiGroup,
                    selectedControlGroup: selectedcontrolAiGroup,
                  },
                });

                updateSelectedCustomAudience(selectedAudience);
              } else {
                dispatch({
                  type: SAVE_CAMPAIGN_AUDIENCE_DETAILS,
                  payload: {
                    selectedAudience: null,
                    selectedGroup: selectedAiGroup,
                    selectedControlGroup: null,
                  },
                });
              }
              if (response.data.audiences.length === 0) {
                setIsLoading(false);
              }
              dispatch(
                setCampaignAudience({
                  value: response.data.audiences,
                  key: "campaignAudience",
                })
              );
            } else {
              setIsLoading(false);
            }
          }
        )
      );
      dispatch(
        getAdsetDetails({ group_id: selectedAiGroup.id, user: {} }, (resp) => {
          setloadingIcon(false);
          updateSelectedAdSetsGroup(resp.data);
        })
      );
    }
  }, [selectedAiGroup, fbAIGroupOptions]);

  useEffect(() => {
    if (selectedAiGroup?.id) {
      dispatch(
        getGroupAdAccounts(
          {
            groupId: selectedAiGroup?.id,
            platformId: "1c59a6e2-843b-4634-92a0-6fa93ce6c761",
          },
          (adaccRes, adaccError) => {
            if (!adaccError || adaccRes.data.length > 0) {
              adaccRes.data.map((adcc) => {
                adcc.name = adcc.adAccountId;
                adcc.id = adcc.adAccountId;
              });
              updateAdAccountOptions(adaccRes.data);
            } else {
              console.log("getAdAccounts ->", adaccError);
              updateAdAccountOptions([]);
            }
          }
        )
      );
    }
  }, [selectedAiGroup]);

  useEffect(() => {
    if (campaignAudience && campaignAudience?.length > 0) {
      setIsLoading(false);
    }
  }, [campaignAudience]);

  const [selectedTab, setSelectedTab] = useState("audienceSelection");

  const handleChangeTab = (event, newValue) => {
    setSelectedTab(newValue);
  };

  const handleSaveButton = () => {
    const data = {
      data: adSetsOption.map((adset) => ({
        id: adset.id,
        recommendedExclusions: adset.recommendedExclusions,
      })),
    };
    dispatch(
      setTAIUpdatedRecommendedExclusions({
        loading: true,
        success: false,
        error: null,
      })
    );
    dispatch(
      updateTAIRecommendedExclusions(
        { data, user, adAccountId: selectedAdAcount?.adAccountId },
        (response, error) => {
          if (!error) {
            dispatch(
              setTAIUpdatedRecommendedExclusions({
                loading: false,
                success: true,
                error: null,
              })
            );
            setOpenModal(false);
          } else {
            console.log("update recommendation failed", error);
            dispatch(
              setTAIUpdatedRecommendedExclusions({
                loading: false,
                success: false,
                error: error,
              })
            );
          }
        }
      )
    );
  };

  const handleAudienceSaveButton = () => {
    if (selectedCustomAudience || selectedControlAiGroup) {
      dispatch(
        saveCampaignAudience(
          {
            customAudienceId: selectedCustomAudience?.id,
            adAccountId: selectedCustomAudience?.account_id,
            controlAiGroup: selectedControlAiGroup?.id,
          },
          selectedAiGroup.id,
          () => {
            dispatch({
              type: SAVE_CAMPAIGN_AUDIENCE_DETAILS,
              payload: {
                selectedAudience: selectedCustomAudience,
                selectedGroup: selectedAiGroup,
                selectedControlGroup: selectedControlAiGroup,
              },
            });
          }
        )
      );
    }
    setOpenModal(false);
  };

  const selectAllAdsets = (boolean) => {
    updateSelectedAdSetsOption(
      adSetsOption.map((item) => ({ ...item, selected: boolean }))
    );
  };

  function CustomSvgIcon() {
    return (
      <>
        {loadingIcon ? (
          <img
            style={{ height: "1em", width: "1em", marginRight: "14px" }}
            src={CommonLoader}
          />
        ) : (
          <CaretDown
            style={{ height: "5px", width: "12px", marginRight: "14px" }}
          />
        )}
      </>
    );
  }

  return (
    <>
      <Box sx={{ width: "100%", height: "100%" }}>
        <Grid
          container
          sx={{
            borderBottom: 1,
            borderColor: "divider",
            height: "12%",
            alignItems: "center",
            justifyContent: "center",
            width: "100%",
          }}
        >
          <Grid item xs={11}>
            <Tabs
              value={selectedTab}
              onChange={handleChangeTab}
              aria-label="basic tabs example"
            >
              <Tab
                label="AI group Configuration"
                sx={{
                  textTransform: "none",
                  fontSize: "14px",
                  fontWeight: "600",
                  fontFamily: "Inter",
                  fontStyle: "normal",
                }}
                value="audienceSelection"
              />
              <Tab
                label="Adset Configuration"
                value="exlusions"
                sx={{
                  textTransform: "none",
                  fontSize: "14px",
                  fontWeight: "600",
                  fontFamily: "Inter",
                  fontStyle: "normal",
                }}
              />
            </Tabs>
          </Grid>
          <Grid item xs={1} textAlign={"end"} pr={2}>
            <IconButton
              onClick={() => {
                setOpenModal(false);
              }}
            >
              <img src={cross} alt="cross-icon" />
            </IconButton>
          </Grid>
        </Grid>
        <TabPanel
          value={"audienceSelection"}
          index={0}
          selectedTab={selectedTab}
        >
          <Stack>
            <Typography
              className="font-lato"
              sx={{ marginBottom: "8px", fontWeight: "600", fontSize: "14px" }}
            >
              Select AI Group
            </Typography>
            {fbAIGroupOptions && (
              <SelectText
                selectedItem={selectedAiGroup}
                setSelectedItem={handleAiGroupDropdown}
                size="small"
                labelId="demo-select-small"
                id="demo-select-small"
                onChange={() => {}}
                valueKey={"id"}
                displayKey={"name"}
                items={fbAIGroupOptions}
              />
            )}
            <Typography
              className="font-lato"
              sx={{
                marginTop: "24px",
                // marginBottom: "8px",
                fontWeight: "600",
                fontSize: "14px",
              }}
            >
              Select the Control AI Group For Google
            </Typography>
            <Typography
              fontSize={12}
              color={"#999999"}
              fontWeight={400}
              marginBottom={1}
            >
              We will be using the data from this Control Ai group to generate
              Cohorts for your Exposed Ai group
            </Typography>

            {fbAIGroupOptions && (
              <SelectText
                selectedItem={selectedControlAiGroup}
                setSelectedItem={handleControlAiGroupDropdown}
                IconComponent={CustomSvgIcon}
                size="small"
                labelId="demo-select-small"
                id="demo-select-small"
                onChange={() => {}}
                valueKey={"id"}
                displayKey={"name"}
                items={fbAIGroupOptions}
              />
            )}
          </Stack>
        </TabPanel>
        <TabPanel value={"exlusions"} index={1} selectedTab={selectedTab}>
          <Grid container width={"100%"} sx={{ display: "block" }}>
            <Box item xs={6} sx={{ maxWidth: "none" }}>
              <Typography className="font-lato font-600 font-14">
                Select AI Group
              </Typography>
              {fbAIGroupOptions && (
                <SelectText
                  selectedItem={selectedAiGroup}
                  setSelectedItem={handleAiGroupDropdown}
                  size="small"
                  labelId="demo-select-small"
                  id="demo-select-small"
                  onChange={() => {}}
                  valueKey={"id"}
                  displayKey={"name"}
                  items={fbAIGroupOptions}
                  sx={{
                    width: "100%",
                    marginTop: "8px",
                  }}
                />
              )}
            </Box>
            <Box item xs={6} sx={{ maxWidth: "none", marginTop: "30px" }}>
              <Typography className="font-lato font-600 font-14">
                Ad Account
              </Typography>
              <Typography
                fontSize={12}
                color={"#999999"}
                fontWeight={400}
                marginBottom={1}
              >
                Select your Meta Ad account to fetch the different Adsets
              </Typography>
              {fbAIGroupOptions && (
                <SelectText
                  selectedItem={selectedAdAcount}
                  setSelectedItem={handleAdAccountDropdown}
                  size="small"
                  labelId="demo-select-small"
                  id="demo-select-small"
                  onChange={() => {}}
                  valueKey={"name"}
                  displayKey={"name"}
                  items={adAccountOptions}
                  sx={{
                    width: "100%",
                    marginTop: "6px",
                  }}
                />
              )}
            </Box>
          </Grid>
          <hr />
          {adSetsOption.length > 0 && selectedAdAcount?.name ? (
            <>
              {adSetsOption.length > 0 && selectedAdAcount?.name && (
                <Box>
                  <Grid container width={"100%"}>
                    <Grid item xs={10}>
                      <Typography
                        fontSize={14}
                        color={"#000000"}
                        fontWeight={600}
                      >
                        Add exclusions for each ad set
                      </Typography>
                      <Typography
                        fontSize={12}
                        color={"#999999"}
                        fontWeight={400}
                      >
                        You can select multiple adsets and type exclusions to
                        write in all selected
                      </Typography>
                    </Grid>
                    <Grid item xs={2}>
                      {adSetsOption.every((item) => item.selected) ? (
                        <Button
                          buttonVariant="text"
                          onClick={() => selectAllAdsets(false)}
                        >
                          <Typography
                            noWrap={true}
                            variant="inherit"
                            color="#0869FB"
                          >
                            Deselect All
                          </Typography>
                        </Button>
                      ) : (
                        <Button
                          buttonVariant="text"
                          onClick={() => selectAllAdsets(true)}
                        >
                          <Typography
                            noWrap={true}
                            variant="inherit"
                            color="#0869FB"
                          >
                            Select All
                          </Typography>
                        </Button>
                      )}
                    </Grid>
                  </Grid>
                </Box>
              )}
              <Box sx={{ maxHeight: "80%" }}>
                {selectedAdAcount?.name &&
                  adSetsOption.map((item, index) => {
                    return (
                      <React.Fragment key={item.id + item.elementId}>
                        <Box
                          sx={{
                            marginTop: "4px",
                          }}
                        >
                          <Box
                            sx={{
                              border: "1px solid #F0F0F0",
                              padding: "12px 4px",
                            }}
                          >
                            <Grid
                              sx={{ display: "flex", alignItems: "center" }}
                              container
                              width={"100%"}
                            >
                              <Grid
                                item
                                sx={{ display: "flex", alignItems: "center" }}
                                xs={7}
                              >
                                <Checkbox
                                  onClick={() => {
                                    let data = [...adSetsOption];
                                    data[index] = {
                                      ...data[index],
                                      selected: !data[index].selected,
                                    };
                                    updateSelectedAdSetsOption(data);
                                  }}
                                  checked={adSetsOption[index].selected}
                                />
                                <Tooltip
                                  placement="top"
                                  title={
                                    item?.name || "Ad Set Name will come here"
                                  }
                                >
                                  <Typography
                                    fontSize={12}
                                    color={"#999999"}
                                    fontWeight={400}
                                    className="adset-name-section"
                                  >
                                    {item?.name || "Ad Set Name will come here"}
                                  </Typography>
                                </Tooltip>
                              </Grid>
                              <Grid item xs={5}>
                                <TextField
                                  onChange={(event) => {
                                    let tempAdSetOption = [...adSetsOption];
                                    for (
                                      let i = 0;
                                      i < tempAdSetOption.length;
                                      i++
                                    ) {
                                      if (tempAdSetOption[i].selected) {
                                        tempAdSetOption[
                                          i
                                        ].recommendedExclusions =
                                          event.target.value;
                                      }
                                    }
                                    updateSelectedAdSetsOption(tempAdSetOption);
                                  }}
                                  disabled={!adSetsOption[index]?.selected}
                                  id="outlined-basic"
                                  label="Type Exclusions (separated by comma)"
                                  variant="outlined"
                                  value={
                                    adSetsOption[index]
                                      ?.recommendedExclusions || ""
                                  }
                                />
                              </Grid>
                            </Grid>
                          </Box>
                        </Box>
                      </React.Fragment>
                    );
                  })}
              </Box>
            </>
          ) : (
            <Box
              sx={{
                width: "100%",
                height: "60%",
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
              }}
            >
              <Box
                sx={{
                  display: "flex",
                  alignItems: "center",
                  flexDirection: "column",
                }}
              >
                <AssignKeywordsIcon />
                <Typography
                  sx={{
                    fontWeight: "500",
                    fontSize: "14px",
                    fontStyle: "italic",
                    color: "#82838F",
                  }}
                >
                  Assign Exclusion keywords against your Ad sets{" "}
                </Typography>
              </Box>
            </Box>
          )}
        </TabPanel>
        <Box sx={{ height: "10%", float: "right" }}>
          {selectedTab === "audienceSelection" ? (
            <Button
              onClick={handleAudienceSaveButton}
              buttonVariant="contained"
            >
              Save
            </Button>
          ) : (
            <Button onClick={handleSaveButton} buttonVariant="contained">
              Save
            </Button>
          )}
        </Box>
      </Box>
    </>
  );
};
const Header = ({
  modalProps,
  showModal,
  setShowModal,
  setModalProps,
  initialProps,
}) => {
  const [openModal, setOpenModal] = useState(false);
  const { showSettingsIconHeader } = useContext(TaiContext);
  const { userInfo } = useSelector((state) => state.userInfoReducer);
  const { user, getAccessTokenSilently } = useAuth0();
  const dispatch = useDispatch();
  const [searchParams] = useSearchParams();
  const isViewedFromBeta = searchParams.get("beta");

  useEffect(() => {
    if (!userInfo?.roles) {
      (async () => {
        try {
          const data = await getAccessTokenSilently();
          dispatch({ type: "GET_USER_INFO", payload: { user, data } });
        } catch (error) {
          console.log("LL: error", error);
        }
      })();
    }
  }, [userInfo]);

  const { formData } = useSelector((state) => state.aiInitiation);
  const { aiGroupOptions } = formData;
  // const isAdmin = userInfo?.roles?.includes("super-admin");
  const aiInitiationState = useSelector((state) => state.aiInitiation.apiData);
  const adAccountState = useSelector((state) => state.adaccount);
  const { brandRoles } = adAccountState;
  return (
    <>
      <div
        className="h-16 w-100 d-flex justify-content-between align-items-center"
        style={{ borderBottom: "1px solid #EFEFEF" }}
      >
        <div className="d-flex align-items-center justify-content-between w-100">
          <div className="d-flex align-items-center justify-content-between" style={{padding:isViewedFromBeta?'6px 0px 6px 16px':''}}>
            {!isViewedFromBeta && <button
              className="mr-0 btn"
              onClick={() => {
                setModalProps(initialProps);
                setShowModal(true);
                dispatch(resetGSCalibrationData());
                dispatch(resetAdditionalInputData());
                dispatch(resetNodesLoaderState());
              }}
              title="Exit TAI Playground"
            >
              <img className="ml-2" src={exitIcon} alt="info_style" />
            </button>}
            <p
              style={{
                margin: "auto 0",
                fontWeight: "bold",
                fontSize: "1.25rem",
                fontFamily: "Lato",
                fontStyle: "normal",
              }}
            >
              Targeting AI Playground
            </p>
            <button className="ml-0 btn">
              {/*<img className="ml-2" src={betaTag} alt="info_style" />*/}
            </button>
          </div>
          <div
            className="d-flex"
            style={{
              justifyContent: "space-around",
              width: "5%",
              alignItems: "center",
            }}
          >
            {aiInitiationState?.platform?.name !== "Google Search" &&
              // isAdmin &&
              // isAdminRole(brandRoles) &&
              getRolesHierarchy(brandRoles) === "admin" &&
              showSettingsIconHeader &&
              aiGroupOptions &&
              Boolean(Object.values(aiGroupOptions).length) && (
                <img
                  alt="Settings "
                  style={{ height: "25px", width: "25px", cursor: "pointer" }}
                  src={SettingIcon}
                  onClick={() => {
                    setOpenModal(true);
                  }}
                ></img>
              )}
          </div>
          {aiInitiationState?.platform?.name && (
            <div>
              <p
                className="px-2 mb-0 mr-3 font-16"
                style={{ color: "#333", fontSize: "14px", fontWeight: "600" }}
              >
                <span className="mr-2">
                  {aiInitiationState?.platform?.name === "Google Search" ? (
                    <GoogleLogo style={{ height: "2rem", width: "2rem" }} />
                  ) : (
                    <FBLogo style={{ height: "2rem", width: "2rem" }} />
                  )}
                </span>
                {aiInitiationState?.platform?.name === "Google Search"
                  ? "Google Search"
                  : "Meta"}
              </p>
            </div>
          )}
        </div>
      </div>
      {openModal ? (
        <Modal open={openModal}>
          {/* <ModalContainer
              submitText={"Submit"}
              footerComponent={Component}
              footerProps={{ setOpenModal }}
              width="28%"
              title="Select Custom audience"
              handleClose={() => {
                setOpenModal(false);
              }}
            ></ModalContainer> */}
          <Box sx={style}>
            <Component setOpenModal={setOpenModal} />
          </Box>
        </Modal>
      ) : (
        <></>
      )}
      {showModal && <StayHereModal {...modalProps} />}
    </>
  );
};

export default Header;
